import { PROFILE_URL } from "@greencross/pages/authenticated/profile/profile.strings";
import { Fragment, h } from "preact";
import { Link } from "preact-router";
import { CONTACT_US_URL } from '../contact-us/contact-us.strings';
import { SIGNUP_URL } from "../sign-up/sign-up.strings";

export const FAQ_URL = "/faqs";
export const FAQ_HEADER = "FAQs";
export const NEED_HELP_HEADER = 'Need help? Contact us.';
export const LOST_CARD_HEADER = 'Lost your card?';


export const NEED_HELP_SERVICE_CONTENT = (
	<Fragment>
		<h3>Customer service</h3>
		<p>Contact centre hours are <br /> Monday to Friday, 9am to 5pm</p>
	</Fragment>
);

export const NEED_HELP_PHONE_CONTENT = (
	<Fragment>
		<p>Phone <span>0800 762 672</span></p>
		<p>Fax <span>(09) 571 9081</span></p>
		<p>Email <a href="mailto:hello@livingrewards.co.nz">hello@livingrewards.co.nz</a></p>
		<br />
		<p>You may also find a solution to your problem in our troubleshooting list for FAQs</p>
	</Fragment>
);

export const LOST_CARD_CONTENT = (
	<p>Please notify your local Unichem or Life Pharmacy or contact 0800 762 672 or email <a href="mailto:hello@livingrewards.co.nz">hello@livingrewards.co.nz</a> to have your card cancelled and a new card issued.</p>
);

export const FAQ_CONTENT: Record<string, Record<string, any>> = {
	'Living Rewards Membership': {
		'How do I sign up?': (
			<p>You can sign up in any Unichem or Life Pharmacy where you will be given a card. If you don’t want a card you can simply set up an account online at <Link href="https://www.livingrewards.co.nz">livingrewards.co.nz</Link>.</p>
		),
		'Forgotten your Living Rewards card number?': (
			<p>Your local Unichem or Life Pharmacy will be able to locate your Living Rewards number for you or you can alternatively contact 0800 762 672 or email us at <a href="mailto:hello@livingrewards.co.nz">hello@livingrewards.co.nz</a>.</p>
		),
		'How do I update my details?': (
			<p>You can update your details in any Unichem or Life Pharmacy, online at <Link target='_blank' href="https://www.livingrewards.co.nz">livingrewards.co.nz</Link>, by contacting Customer Services at 0800 762 672, or by emailing us at <a href="mailto:hello@livingrewards.co.nz">hello@livingrewards.co.nz</a>.</p>
		),
		'How do I cancel my/my family members Living Rewards account?': (
			<p>If you wish to delete your account, you may do so online, by contacting Customer Services at 0800 762 672 or by emailing us at <a href="mailto:hello@livingrewards.co.nz">hello@livingrewards.co.nz</a>. You can only cancel a family members’ membership in the event they are no longer capable of doing so themselves, in which case we require authorisation in writing (email is fine). This should include a reason and your relationship. Please also include a contact phone number in case we need to verify anything. Once a membership is cancelled, all points and vouchers are forfeited and cannot be reinstated.</p>
		),
		'Can I merge my points/account with someone else (my partner)?': (
			<p>The Living Rewards programme is for individual members only. Points cannot be combined or gifted at this time. If you and your partner share an email, you will receive communication for both individually.</p>
		),
		'What benefits beyond points are there for Living Rewards members?': (
			<p>Exclusive promotions including opportunities to win prizes, invitations to VIP events, and member only pricing are some of the benefits. You will also get health and beauty updates if your email address is up to date and your permissions allow us to send you marketing communications.</p>
		),
		'Can I still use my Living Card or my Radius Rewards?': (
			<p>We used to run the loyalty programmes Living Card at Life Pharmacy and Radius Rewards at Radius Pharmacy however these were merged to become Living Rewards when we rebranded in 2014. If you have a Living Card or Radius Rewards, your details should have been transferred to a Living Rewards account. Ask next time you shop at Unichem or Life Pharmacy nationwide to get a new card.</p>
		)
	},
	'Living Rewards Vouchers': {
		'When do I get issued a voucher?': (
			<p>You will be issued a voucher when you reach a balance of 150 points.</p>
		),
		'When I achieve 150 points in a transaction, am I able to use those points on the same transaction that day?': (
			<p>You can spend this in the next transaction you make, not in the same purchase.</p>
		),
		'When do vouchers expire?': (
			<p>Vouchers expire 1 month from the date they are issued.</p>
		),
		'Where can I redeem my Living Rewards vouchers?': (
			<p>Vouchers can be redeemed in-store at any Unichem or Life Pharmacy and online at <Link target='_blank' href="https://www.lifepharmacy.co.nz">lifepharmacy.co.nz</Link>.</p>
		),
		'Can I spend my vouchers on anything in store?': (
			<p>Vouchers cannot be used to purchase gift cards, hearing aids and audiology services, calling cards and mobile top-ups, beauty therapy services, shipping or gift wrapping.</p>
		),
		'Can I spend more than one voucher at a time?': (
			<p>Yes</p>
		),
		'I lost my voucher. What can I do?': (
			<p>Your voucher is stored electronically in your account for 3 months. Just produce your Living Rewards Card or other suitable identification when you wish to redeem it.</p>
		)
	},
	'Living Rewards Points': {
		'How do I earn points': (
			<Fragment>
				<p>Every time you shop in a Unichem or Life Pharmacy and swipe your Living Rewards card you will earn 1 point for every $1 you spend on retail purchases and 1 point per paid prescription item. Sign in with your Living Rewards account details when you shop online at <Link href="https://www.livingrewards.co.nz">livingrewards.co.nz</Link> and you will earn points there too.</p>
				<p>Points are allocated per item using Swedish rounding, not on total transaction spend. See the question below for exclusions.</p>
			</Fragment>
		),
		'Do I earn points on everything I purchase?': (
			<p>You will earn points on most products, however gift cards, hearing aids and audiology services, calling cards and mobile top-ups, beauty therapy services, delivery fees, and gift wrapping are excluded. Points may also be excluded from time to time during certain promotions.</p>
		),
		'Why are prescriptions only 1 point?': (
			<p>There are many different government subsidies for prescriptions. To make it easier to process, we reward 1 point for each dispensary no matter the cost to ensure you are still being rewarded.</p>
		),
		'Where can I earn points?': (
			<p>You can earn points at any Unichem or Life Pharmacy nationwide, as well as online at <Link target='_blank' href="https://www.lifepharmacy.co.nz">lifepharmacy.co.nz</Link> if you enter your Living Rewards membership number during the check-out process.</p>
		),
		'How much do I need to spend to earn rewards?': (
			<p>Once you have earned 150 points you will receive a $10 Living Rewards Voucher.</p>
		),
		'Can points accumulate?': (
			<p>Points only accumulate up to 150, when you reach this you will receive a $10 voucher you can use on your next purchase. If you earn over 300 points in a single purchase you will receive a $20 voucher and so on. Points do expire in 12 months, so we encourage you to keep an eye on them to make the most of your rewards.</p>
		),
		'Do my points expire?': (
			<p>Yes, points expire 12 months after they are earned.</p>
		),
		'How do I know how many points I have?': (
			<p>Your current points reward balance is displayed on your till receipt. If you provide us with your email address, you will also find your points balance on the emails we send you. You can also register and login to <Link href="https://www.livingrewards.co.nz">livingrewards.co.nz</Link> to check your points balance at any time.</p>
		),
		'How do I know if my points are expiring?': (
			<p>If you have any points expiring in the next month they will appear at the top of your emails.</p>
		),
		'My points have gone, but I have not received rewards': (
			<p>If you have not shopped for 12 months or more your points will have expired.  If you had a points balance of 150 then a voucher will have been issued. Vouchers must be used within 3 months of issue. If you need further assistance, please call our contact centre Mon-Fri, 9am-5pm on 0800 762 672.</p>
		),
		'Forgot to swipe your Living Rewards card on a purchase?': (
			<p>We don’t want you to miss out! Scan the receipt through to <a href="mailto:hello@livingrewards.co.nz">hello@livingrewards.co.nz</a> with your membership number within 30 days and we will add the points to your account.</p>
		),
		'Can I earn points on purchases made online?': (
			<p>Yes, you can earn points when shopping at <Link target='_blank' href="https://www.lifepharmacy.co.nz">lifepharmacy.co.nz</Link>. Sign in to your Living Rewards account before or during checkout using the same email address or card number that you use to sign on to this site.</p>
		),
		'How many points do I earn when the price is not a round number?': (
			<p>Points are calculated $1 = 1 point for each eligible retail item using Swedish rounding, not on total transaction spend. That means if the price of an eligible item is $19.99, you will earn 20 points, but if the price is $19.49, you will earn 19 points.</p>
		)
	},
	'Living Rewards Card': {
		'I have just signed up online, will I receive a card?': (
			<p>If you sign up online you are not automatically sent a card. Signing up online is a good option for those who don’t want a card. You can simply let the pharmacy know your name or your member number and they will be able to look you up and apply points when you shop in-store.</p>
		),
		'Do I have to present my card when making a purchase?': (
			<Fragment>
				<p>When shopping in-store, no, we can find your Living Rewards membership in our system by searching for your name, phone number or address. If you have a common name this may take some time.</p>
				<p>When shopping online at <Link target='_blank' href="https://www.lifepharmacy.co.nz">lifepharmacy.co.nz</Link>, you do need to sign in to your Living Rewards account first using your registered email or card number, and your self-selected password. To register your card for shopping online, visit <Link target='_blank' href="https://www.lifepharmacy.co.nz/register">lifepharmacy.co.nz/register</Link>.</p>
			</Fragment>
		),
		'Need a new card?': (
			<p>If you've lost your card please notify your local Unichem or Life Pharmacy, contact 0800 762 672, or email us at <a href="mailto:hello@livingrewards.co.nz">hello@livingrewards.co.nz</a> to have your card cancelled and a new one issued.</p>
		),
		'I have 2 cards, what should I do?': (
			<p>You will need to get your membership records merged to one card. This can be done via our Customer Services team on 0800 762 672 or if you email us <a href="mailto:hello@livingrewards.co.nz">hello@livingrewards.co.nz</a>. Please ensure you destroy the card you no longer need.</p>
		)
	},
	'Promotions, Emails & Special Offers': {
		'Why do you want my email?': (
			<p>Email is the most efficient way of keeping you informed of your points and voucher balances, and to let you know about any member exclusive deals. We do not share your email address or any of your personal information with any 3rdparties except as required by law. Please view our privacy policy <a target='_blank' href="http://www.greencrosshealth.co.nz/privacy-policy">here</a>.</p>
		),
		'Why do you want my address?': (
			<p>From time to time you may receive direct mail from us such as vouchers, VIP invitations, special coupons, samples, or other Living Rewards exclusive offers. </p>
		),
		'My emails go to junk, what should I do?': (
			<Fragment>
				<p>ISP’s such as Gmail, Hotmail, and Outlook and your network or security settings can sometimes send emails directly to your junk folder. Living Rewards employ the best email marketing standards to maintain reputation and assist with delivery, however, ISP’s frequently change their settings. We recommend you add email@livingrewards.greencrosshealth.co.nz and noreply@livingrewards.co.nz to your safe senders list.</p>
				<p>You can do this in Microsoft Outlook by accessing the 'Never block sender's domain' tab under 'Junk' within the delete section of the top menu whilst viewing one of our emails. This will automatically add our email domain name, @livingrewards.co.nz, to your safe senders list.</p>
				<p>For more information about adding safe senders to your contacts with some of the major email providers, please visit the links below:</p>
				<ul>
					<li><a href="http://gmail.com/">Gmail</a></li>
					<li><a href="http://www.hotmail.com/">Hotmail</a></li>
				</ul>
			</Fragment>
		),
		'I am not receiving any mail or emails, what should I do?': (
			<p>You can check and update your details when logged in to <Link href="https://www.livingrewards.co.nz">livingrewards.co.nz</Link>. If you can’t login, you can check your details in any Unichem or Life Pharmacy or by contacting Customer Services 0800 762 672. While you are there, check the permission boxes are ticked for the communications type you wish to receive to ensure you have allowed communications from us.</p>
		),
		'How do I opt out of receiving mail or emails?': (
			<p>You can opt out of emails by clicking the unsubscribe link in the email you receive from us. Service delivery emails do not include an email opt out as they are essential communications regarding the programme and your account rather than marketing. You can also update your preferences in the “My Details” section of your online account. </p>
		),
		'How do bonus points work?': (
			<Fragment>
				<p>For a bonus points promotion, you will earn the standard 1 point per $1 retail spend on the product plus the additional advertised points when you make a purchase.</p>
				<p>Most of the time bonus points offers are instant. However in some cases, for example when you receive an exclusive offer that is not available to all customers, the additional bonus points will be applied within 30 days of the promotional period.</p>
			</Fragment>
		),
		'Where can I find out about Living Rewards promotions?': (
			<p>Store-wide Living Rewards promotions can be found online under <Link href="/member-exclusives">Member Exclusives</Link>. Sometimes offers are directly emailed or posted to members; these offers will not be listed online as they are exclusive to a select customer list. Make sure your contact details are up to date so you don't miss out on exclusive communications.</p>
		),
		'If I enter a competition, how will I know if I win?': (
			<p>Winners of competitions are contacted by phone. Please ensure we have your correct details so you are eligible for prize draws.</p>
		)
	},
	'Using the website': {
		"Can't sign in, set up or activate your account?": (
			<p>Sometimes your details are captured incorrectly or are not up to date in our system, making sign in and activation difficult. If the sign in page shows an error message, please contact 0800 762 672 or email <a href="mailto:hello@livingrewards.co.nz">hello@livingrewards.co.nz</a>. You should get a response within 2 working days. Our Customer Service hours are Monday to Friday, 9am - 5pm excluding public holidays.</p>
		),
		'Can I view my transaction history and where I have earned points?': (
			<p>In future, if you set up an online account you will be able to manage your account via the “My Rewards” tab. This function is under construction and should be available for customers to view soon. For now you can ask in-store or contact our customer services team with any questions you may have.</p>
		),
		'Is there a Living Rewards app?': (
			<p>We are in the process of developing a new app to be released in 2017. If you want to check your balance on the go, register online and use our mobile friendly website.</p>
		),
		"What's the difference between Living Rewards and gift cards?": (
			<p>Gift cards can be purchased as a card with credit on it, commonly purchased as a gift for someone. The Living Rewards card is a loyalty membership, allowing you to earn points on your purchases. You cannot earn Living Rewards points on purchasing gift cards however if you spend a gift card to pay for your transaction you can earn points on that transaction.</p>
		),
		'How to enable location request in my browser.': (
			<Fragment>
				<p>If you see the message "<strong>You have denied location request. Please enable to help us improve accuracy of your search results</strong>" you can use the following instructions to enable it.</p>
				<p>Doing this will allow your browser to ask for your permission to let the website use your location.</p>
				<p><strong>Instructions for Chrome</strong></p>
				<ol>
					<li>In Chrome click the GPS icon in your address bar.</li>
					<li>This will open a dialog where you can click "Clear these settings for future visits".</li>
					<li>Refresh the page and a small dialog will appear asking you to allow the site to know your location.</li>
				</ol>
				<p><strong>Instructions for Internet Explorer 11</strong></p>
				<ol>
					<li>In Internet Explorer click the cog icon  in the top right hand corner.</li>
					<li>Select "Internet options".</li>
					<li>In the dialog that opens click the "Privacy" tab.</li>
					<li>Click the "Clear Sites" button in the "Location" section. (Please be aware this will clear all sites you have previously denied access to using your location).</li>
					<li>Click "Ok".</li>
					<li>When you refresh your page you should be asked if you want to allow the website to track your location.</li>
				</ol>
				<p><strong>Instructions for Microsoft Edge on Windows 10.</strong></p>
				<ol>
					<li>Click the Start button on your computer.</li>
					<li>Click settings.</li>
					<li>Go to "Privacy".</li>
					<li>Click "Location" on the left hand menu.</li>
					<li>Scroll down to the "Choose apps that can use your location" section.</li>
					<li>Select "On" for Microsoft Edge.</li>
					<li>Refresh the page and it should ask your permission to use your location again.</li>
				</ol>
			</Fragment>
		)
	},
	'Having issues?': {
		'I have tried to reset my password multiple times and it is not working.': (
			<Fragment>
				<p>Check the junk/spam folder as the reset request may have landed in that inbox.</p>
				<p>If you are a Gmail user, check the Promotions folder as the reset request may have landed in that inbox.</p>
				<p>Try using popular internet browsers such as Google Chrome or Safari if you are not already.</p>
				<p>If you cannot click on the reset password link provided in the reset email, it is still possible to use it by copy and pasting the link into your browser.</p>
			</Fragment>
		),
		'I have a Living Rewards account, but my email address is not recognised when I try and log in online.': (
			<Fragment>
				<p>Make sure that your Living Rewards number/card is connected to an active online account, you do this here: <a href={SIGNUP_URL}>https://www.livingrewards.co.nz{SIGNUP_URL}</a></p>
				<p>If you have not created an online account with us already, you will need to register your Living Rewards Card online before you can use it to shop. Please register your card using the link below and follow the instructions on the webpage. This will enable you to set up an account and password so that you can use it to shop online. <a href={SIGNUP_URL}>https://www.livingrewards.co.nz{SIGNUP_URL}</a></p>
				<p>During the registration process you will receive an email to set your password for your account. Please check your junk/spam folders as well as your inbox in case the email is sent here. You will need to click the link in the email (or copy and paste it into your browser) to set your password.</p>
			</Fragment>
		),
		'The membership number on my Living Rewards account is different to my physical Living Rewards card.': (
			<Fragment>
				<p>If you have an existing Living Rewards card the correct signup location is here: <a href={SIGNUP_URL}>https://www.livingrewards.co.nz{SIGNUP_URL}</a> this will connect your existing card to a new online account.</p>
				<p>If you have created a new account online already and have not used the above process.  We will need to merge both accounts and deactivate the card you do not use.  Your points will accumulate when we merge these accounts and any vouchers you have will go onto the card you want to keep.  Please remember if the total of your points after the merge is greater than 150 points it will not automatically trigger a voucher, this will happen on your first purchase.</p>
			</Fragment>
		),
		'I made an online purchase; however, I have not received any confirmation email.': (
			<Fragment>
				<p>Please make sure to check your Junk/Spam folders as the confirmation email may have ended up there.</p>
				<p>If you are a Gmail user, check the Promotions folder as the confirmation email may have ended up there.</p>
				<p>On <a target='_blank' href="https://www.lifepharmacy.co.nz/">https://www.lifepharmacy.co.nz/</a> on the top right of your screen use the drop down arrow next to your email when you are logged into your Life Pharmacy account and select ‘Order History’. If you can see your order that you just made in this section, then you can be assured we have received and will process your order.</p>
				<p>If you have attempted all the above with no success, please contact us through our online form here: <a target='_blank' href={CONTACT_US_URL}>https://www.lifepharmacy.co.nz/contact-us/</a> or for queries about your online order: 0800 753 423 or (+64) 09 274 4054 (when calling from overseas).</p>
			</Fragment>
		),
		'I made a purchase online and I have not received the Living Rewards points that I should have.': (
			<Fragment>
				<p>This is likely because you were not logged into your Life Pharmacy/Living Rewards account at the time of purchase, please ensure that you are logged in when you shop so that your points are added automatically to your account.</p>
				<p>For us to add these points in this situation please send us a copy of your receipt and Living Rewards card number to <a href="mailto:hello@livingrewards.co.nz">hello@livingrewards.co.nz</a> so that we can manually add the points for you.</p>
			</Fragment>
		),
		'I made an online purchase but was not asked for my card number. Will the points connect through?': (
			<Fragment>
				<p>If you are logged into your connected Living Rewards/Life Pharmacy account at time of purchase, your points will be automatically added to your account. However, if you have not connected your Living Rewards card to your online account or are not logged in at the time of purchase no points will be added.</p>
				<p>For us to add these points in this situation please send us a copy of your receipt and Living Rewards card number to <a href="mailto:hello@livingrewards.co.nz">hello@livingrewards.co.nz</a> so that we can manually add the points for you.</p>
			</Fragment>
		),
		'I made an online order and used my existing login but have not received Living Rewards points. What do I need to do to ensure that this membership number is always associated with my login?': (
			<Fragment>
				<p>To verify if a Living Rewards number is associated with your Life Pharmacy account, you can sign into your account on either: <a target='_blank' href="https://www.lifepharmacy.co.nz/">https://www.lifepharmacy.co.nz/</a> OR <a target='_blank' href="https://www.livingrewards.co.nz/">https://www.livingrewards.co.nz/</a> These two platforms use the same account information (username/password).</p>
				<p>In a logged in state, on the top right of your screen use the drop down arrow next to your email and select “My Details” to verify that you are at the correct location you can look in the address bar and it should read <a target='_blank' href="https://www.lifepharmacy.co.nz/my-account/profile/">https://www.lifepharmacy.co.nz/my-account/profile/</a> or <a href={PROFILE_URL}>https://www.livingrewards.co.nz{PROFILE_URL}</a> depending on which platform you use, the layouts of both of these look identical.</p>
				<p>If under <strong>My Details </strong>you can see Membership Number: 1003750077555 for example, then your membership number is correctly associated with your account.</p>
				<p>If under <strong>My Details </strong>you cannot see ‘Membership Number’ then there is no Living Rewards card associated with this account. Please connect your account here <a href={SIGNUP_URL}>https://www.livingrewards.co.nz{SIGNUP_URL}</a>.</p>
				<p>If you continue to have issues with getting your Living Rewards number associated with your account, please email us at <a href="mailto:hello@livingrewards.co.nz">hello@livingrewards.co.nz</a> with your Life Pharmacy/Living Rewards account login email and the card number that you wish to associate with the account (Note: Your membership number is located on the back of your Living Rewards card).</p>
			</Fragment>
		),
		'My Password reset token is expired.': (
			<Fragment>
				<p>If you are experiencing ‘Token expired’ when attempting to reset your password, wait for 10 minutes and attempt to reset again by generating a new token. If the email has landed in your Junk/Spam/Promotions inbox then links will be disabled by default, in this case copy the token provided into your browser.</p>
				<p>Here is an example of what to copy and paste into your browser:</p>
				<p>https:/www.lifepharmacy.co.nz/join/create-password/?token=3458e88e-57cc-473c-b0b5-cb0b18748b99</p>
				<p><em>(Note: Please do not copy and paste this link. You will receive a similar link in the password reset email which you will need to click on or copy into your browser).</em></p>
				<p>If you continue to have issues with token expiry, please contact our customer service contact centre. Monday to Friday, 9am to 5pm. Phone: 0800 762 672</p>
			</Fragment>
		),
		'If I change my password on Living Rewards will it also update my password on Life Pharmacy?': (
			<p>Yes, <a href="/">https://www.livingrewards.co.nz/</a> and <a target='_blank' href="https://www.lifepharmacy.co.nz/">https://www.lifepharmacy.co.nz/</a> accounts are linked, and updating any information including passwords on one will also update the other automatically.</p>
		),
		'I want to update my email address that I use for my account, but it will not let me.': (
			<p>Currently customers are unable to update their email address directly online. However, we can do this for you from our support office. Please email <a href="mailto:hello@livingrewards.co.nz">hello@livingrewards.co.nz</a> from your current Living Rewards email address with the email that you wish to update to and we will change it as soon as we can.</p>
		),
		'How do I add your emails to my ‘safe sender’ list?': (
			<Fragment>
				<p>For Gmail accounts you will need to find the email in your spam/junk folder and click on the three vertical dots and click ‘Add Life Pharmacy to Contacts list’. This will move the email to your inbox and ensure any future emails from us land in there.</p>
				<p>For Outlook accounts ensure they are put in your Inbox, click on your Junk Email folder. Then right-click the message labelled as Junk and select Junk &gt;&gt; Not Junk from the context menu. The Mark as Not Junk box comes up. Check Always Trust Email From Online.support@lifepharmacy.co.nz and check your email address below that.</p>
			</Fragment>
		)
	}
}