export const MY_REWARDS_PAGE_TITLE = 'My Rewards';
export const MY_REWARDS_PAGE_WELCOME_TEXT = 'Welcome';
export const MY_REWARDS_PAGE_MEMBERSHIP_TEXT = 'Membership No:';
export const MY_REWARDS_PAGE_TOTAL_POINTS_TEXT = 'TOTAL POINTS';
export const MY_REWARDS_PAGE_MY_POINTS_BALANCE_TEXT = 'MY POINTS BALANCE';
export const MY_REWARDS_PAGE_DONT_FORGET_TEXT =
  "Don't forget your Living Rewards Vouchers are only valid for 3 months.";
export const MY_REWARDS_PAGE_MY_VOUCHERS_TEXT = 'MY VOUCHERS';
export const MY_REWARDS_PAGE_AVAILABLE_TEXT = 'AVAILABLE';
export const MY_REWARDS_PAGE_REMAINING_POINTS_TEXT =
  "You're only <strong>%remainingPoints% points</strong> away from your next <strong>$%voucherValue% voucher.</strong>";
